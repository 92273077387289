// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-impressum-index-js": () => import("./../../../src/pages/impressum/index.js" /* webpackChunkName: "component---src-pages-impressum-index-js" */),
  "component---src-pages-kontakt-index-js": () => import("./../../../src/pages/kontakt/index.js" /* webpackChunkName: "component---src-pages-kontakt-index-js" */),
  "component---src-pages-neues-index-js": () => import("./../../../src/pages/neues/index.js" /* webpackChunkName: "component---src-pages-neues-index-js" */),
  "component---src-pages-projekte-index-js": () => import("./../../../src/pages/projekte/index.js" /* webpackChunkName: "component---src-pages-projekte-index-js" */),
  "component---src-pages-termine-index-js": () => import("./../../../src/pages/termine/index.js" /* webpackChunkName: "component---src-pages-termine-index-js" */),
  "component---src-pages-vita-index-js": () => import("./../../../src/pages/vita/index.js" /* webpackChunkName: "component---src-pages-vita-index-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-neuigkeit-js": () => import("./../../../src/templates/neuigkeit.js" /* webpackChunkName: "component---src-templates-neuigkeit-js" */),
  "component---src-templates-projekt-js": () => import("./../../../src/templates/projekt.js" /* webpackChunkName: "component---src-templates-projekt-js" */)
}

